<template>
    <div>

        <body>
            <!-- <div class="responsive"></div> -->
            <!--[if lte IE 9]>
      <p class="browserupgrade">
        You are using an <strong>outdated</strong> browser. Please upgrade your browser</a> to improve
        your experience and security.
      </p>
    <![endif]-->

            <!-- ======== preloader start ======== -->
            <!-- <div class="preloader">
        <div class="loader">
            <div class="spinner">
                <div class="spinner-container">
                    <div class="spinner-rotator">
                        <div class="spinner-left">
                            <div class="spinner-circle"></div>
                        </div>
                        <div class="spinner-right">
                            <div class="spinner-circle"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
            <!-- preloader end -->

            <!-- ======== header start ======== -->
            <header class="header">
                <div class="navbar-area">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-lg-12">
                                <nav class="navbar navbar-expand-lg">
                                    <a class="navbar-brand" href="index.html">
                                        <img src="@/homepage-assets/images/logo/logo.svg" alt="Softinya LMS" />
                                    </a>
                                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                        aria-expanded="false" aria-label="Toggle navigation">
                                        <span class="toggler-icon"></span>
                                        <span class="toggler-icon"></span>
                                        <span class="toggler-icon"></span>
                                    </button>

                                    <div class="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
                                        <ul id="nav" class="navbar-nav">
                                            <!--ms-auto-->
                                            <li class="nav-item">
                                                <a class="" href="#home">{{ this.$t('messages.login') }}</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="" href="#features">{{
                                                    $t('homepage.whats') }}?</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="" href="#about">{{
                                                    $t('homepage.What_Can_I_Do') }}?</a>
                                            </li>

                                            <li class="nav-item">
                                                <a class="" href="#properties">{{
                                                    $t('homepage.features') }}</a>
                                            </li>
                                            <li class="nav-item">
                                                <a href="/#/contact">{{
                                                    $t('homepage.contact') }}</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <!-- navbar collapse -->

                                    <!-- <a href="#" class="main-btn border-btn btn-hover d-none d-sm-block"><i class="lni lni-calendar"></i> Bir Demo Planlayın</a> -->
                                    <!-- <a style="border:2px solid #fff !important; font-weight:600;" href="/#/login" class="main-btn border-btn btn-hover d-none d-sm-block"><i class="lni lni-arrow-right-circle"></i> Giriş Yap</a> -->

                                </nav>
                                <!-- navbar -->
                            </div>
                        </div>
                        <!-- row -->
                    </div>
                    <!-- container -->
                </div>
                <!-- navbar area -->
            </header>
            <!-- ======== header end ======== -->



            <!-- ======== hero-section start ======== -->
            <section class="hero-section v2">
                <div class="container">
                    <div class="row align-items-center position-relative">
                        <div class="col-lg-6 offset-lg-3">
                            <div class="hero-content">
                                <br>
                                <br>
                                <br>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <!-- ======== hero-section end ======== -->


            <!-- ======== contact-section start ======== -->
            <div class="container">
                <div class="contact__wrapper shadow-lg mt-n9">
                    <div class="row no-gutters">
                        <div class="col-lg-5 contact-info__wrapper p-5 order-lg-2">
                            <h3 class="color--white mb-5">{{
                                $t('homepage.reach_out_to_us') }}</h3>

                            <ul class="contact-info__list list-style--none position-relative z-index-101">
                                <li class="mb-4 pl-4">
                                    <i class="lni lni-envelope"></i> info@softinya.com
                                </li>
                                <li class="mb-4 pl-4">
                                    <i class="lni lni-phone"></i> (0212) 267 53 73
                                </li>
                                <li class="mb-4 pl-4">
                                    <i class="lni lni-whatsapp"></i> (0534) 522 08 59
                                </li>
                                <li class="mb-4 pl-4">
                                    <span class="position-absolute"><i class="fas fa-map-marker-alt"></i></span> Esentepe
                                    Mah. Büyükdere Cd.
                                    <br> Müselles Sk. Onur İş Merkezi
                                    <br> No:1/1 K:6 Şişli - İstanbul
                                </li>
                            </ul>

                        </div>

                        <div class="col-lg-7 contact-form__wrapper p-5 order-lg-1">
                            <form action="#" class="contact-form form-validate" novalidate="novalidate">
                                <div class="row">
                                    <div class="col-sm-6 mb-3">
                                        <div class="form-group">
                                            <label class="required-field" for="firstName">{{
                                                $t('homepage.name') }}</label>
                                            <input type="text" class="form-control" id="firstName" name="firstName"
                                                placeholder="">
                                        </div>
                                    </div>

                                    <div class="col-sm-6 mb-3">
                                        <div class="form-group">
                                            <label for="lastName">{{
                                                $t('homepage.surname') }}</label>
                                            <input type="text" class="form-control" id="lastName" name="lastName"
                                                placeholder="">
                                        </div>
                                    </div>

                                    <div class="col-sm-6 mb-3">
                                        <div class="form-group">
                                            <label class="required-field" for="email">{{
                                                $t('homepage.email') }}</label>
                                            <input type="text" class="form-control" id="email" name="email" placeholder="">
                                        </div>
                                    </div>

                                    <div class="col-sm-6 mb-3">
                                        <div class="form-group">
                                            <label for="phone">{{
                                                $t('homepage.tel') }}</label>
                                            <input type="tel" class="form-control" id="phone" name="phone" placeholder="">
                                        </div>
                                    </div>

                                    <div class="col-sm-6 mb-3">
                                        <div class="form-group">
                                            <label class="required-field" for="email">{{
                                                $t('homepage.company') }}</label>
                                            <input type="text" class="form-control" id="company" name="company"
                                                placeholder="">
                                        </div>
                                    </div>

                                    <div class="col-sm-6 mb-3">
                                        <div class="form-group">
                                            <label class="required-field" for="email">{{
                                                $t('homepage.rol') }}</label>
                                            <input type="text" class="form-control" id="role" name="role" placeholder="">
                                        </div>
                                    </div>

                                    <div class="col-sm-12 mb-3">
                                        <div class="form-group">
                                            <label class="required-field" for="message">{{
                                                $t('homepage.desc') }}</label>
                                            <textarea class="form-control" id="message" name="message" rows="4"
                                                placeholder=""></textarea>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 mb-3">
                                        <button type="submit" name="submit" class="btn main-btn">{{
                                            $t('homepage.send') }}</button>
                                    </div>

                                </div>
                            </form>
                        </div>
                        <!-- End Contact Form Wrapper -->

                    </div>
                </div>
            </div>
            <!-- ======== properties-section end ======== -->

            <!-- ======== footer start ======== -->
            <footer class="footer ">
                <div class="container ">
                    <div class="widget-wrapper ">
                        <div class="row ">
                            <div class="col-xl-4 col-lg-4 col-md-6 ">
                                <div class="footer-widget ">
                                    <div class="logo mb-30 ">
                                        <a href="index.html ">
                                            <img src="@/homepage-assets/images/logo/logo.svg" alt="Softinya LMS" />
                                        </a>
                                    </div>
                                    <p class="desc mb-30 text-white ">
                                        <a href="mailto:info@softinya.com"><i class="lni lni-envelope"></i>
                                            info@softinya.com</a>
                                        <a href="tel:0(212)-267-53-73"><i class="lni lni-phone"></i> (0212) 267 53 73</a>
                                        <a href="tel:0(534)-522-08-59"><i class="lni lni-whatsapp"></i> (0534) 522 08 59</a>
                                    </p>
                                </div>
                            </div>

                            <div class="col-xl-2 col-lg-2 col-md-6 ">
                                <div class="footer-widget mt-25">
                                    <ul class="links ">
                                        <li><a href="/#/">{{ this.$t('messages.login') }}</a></li>
                                        <li><a href="#features">{{
                                            $t('homepage.whats') }}?</a></li>
                                        <li><a href="#about">{{
                                            $t('homepage.What_Can_I_Do') }}?</a></li>
                                    </ul>
                                </div>
                            </div>

                            <div class="col-xl-3 col-lg-3 col-md-6 ">
                                <div class="footer-widget mt-25">
                                    <ul class="links ">
                                        <li><a href="#properties">{{
                                            $t('homepage.features') }}</a></li>
                                        <li><a href="/#/contact">{{
                                            $t('homepage.contact') }}</a></li>
                                        <li><a href="/#/policies">{{
                                            $t('homepage.agreements') }}</a></li>
                                    </ul>
                                </div>
                            </div>

                            <div class="col-xl-3 col-lg-3 col-md-6 ">
                                <div class="footer-widget ">
                                    <ul class="socials mt-80">
                                        <li>
                                            <a target="_blank" href="https://www.instagram.com/anzeraofficial">
                                                <i class="lni lni-instagram-filled "></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a target="_blank"
                                                href="https://www.linkedin.com/company/anzeraogrenmeteknolojileri">
                                                <i class="lni lni-linkedin-original "></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </footer>
            <!-- ======== footer end ======== -->

            <!-- ======== scroll-top ======== -->
            <a href="# " class="scroll-top btn-hover ">
                <i class="lni lni-chevron-up "></i>
            </a>

        </body>


    </div>
</template>
<script>

export default {
    name: "contact",

    computed: {
    },

    methods: {
    },
    mounted() {

    }
};
</script>

<style scoped>
/* <link rel="stylesheet" href="@/homepage-assets/css/bootstrap.min.css" />
    <link rel="stylesheet" href="@/homepage-assets/css/lineicons.css" />
    <link rel="stylesheet" href="@/homepage-assets/css/animate.css" />
    <link rel="stylesheet" href="@/homepage-assets/css/main.css" />
    <link rel="stylesheet" href="@/homepage-assets/css/custom.css" /> */
@import '../../homepage-assets/css/bootstrap.min.css';
@import '../../homepage-assets/css/lineicons.css';
@import '../../homepage-assets/css/animate.css';
@import '../../homepage-assets/css/main.css';
@import '../../homepage-assets/css/custom.css';
</style>